"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Slider = void 0;
// Imports
var core_module_1 = require("../../@kilopix/core.module");
var app_component_1 = require("../app.component");
var hammerjs_1 = require("@egjs/hammerjs");
// Slider
var Slider = /** @class */ (function () {
    function Slider(slider) {
        this.nodes = {};
        this.data = {};
        this.init(slider);
        this.listeners();
    }
    // Resize
    Slider.prototype.resize = function () {
        if (this.getVisibleItems() != this.data.previousVisibleItems) {
            this.data.previousVisibleItems = this.getVisibleItems();
            this.data.pageId = 0;
            this.setCounter();
            this.setListWidth();
            this.setItemsWidth();
            this.setListPosition(0);
            this.updateNavigation();
            return;
        }
        this.setCounter();
        this.setListWidth();
        this.setItemsWidth();
        this.setListPosition();
        this.updateNavigation();
    };
    // Init
    Slider.prototype.init = function (slider) {
        this.nodes.root = slider;
        this.nodes.wrap = slider.querySelector(".slider-wrap");
        this.nodes.items = slider.querySelector(".slider-items");
        this.nodes.list = slider.querySelector(".slider-items ul");
        this.nodes.li = slider.querySelectorAll(".slider-items li");
        this.nodes.prev = slider.querySelector("button:first-child");
        this.nodes.next = slider.querySelector("button:last-child");
        this.nodes.counter = slider.querySelector(".counter");
        this.nodes.counterCurrent = slider.querySelector(".current");
        this.nodes.counterTotal = slider.querySelector(".total");
        this.data.type = (slider.classList.contains("slider-mini")) ? "mini" : "default";
        this.data.length = this.nodes.li.length;
        this.data.isAnimate = false;
        this.data.isSwipe = false;
        this.data.pageId = 0;
        if (this.data.type === "mini") {
            this.nodes.list.classList.add("no-transition");
        }
    };
    // Listeners
    Slider.prototype.listeners = function () {
        var _this = this;
        if (this.data.type === "default") {
            this.nodes.prev.addEventListener("click", function (e) {
                if ((_this.data.pageId - 1) < 0)
                    return;
                _this.data.pageId--;
                _this.updateNavigation();
                _this.setListPosition();
                _this.setCounter();
            });
        }
        this.nodes.next.addEventListener("click", function (e) {
            if (_this.data.type === "mini") {
                if (_this.data.isAnimate || _this.data.length == 1)
                    return;
                _this.data.isAnimate = true;
                _this.data.pageId++;
                if (_this.data.pageId >= _this.data.length) {
                    _this.data.pageId = 0;
                }
                _this.nodes.list.classList.remove("no-transition");
            }
            else {
                if ((_this.data.pageId + 1) > _this.getTotalPages())
                    return;
                _this.data.pageId++;
                _this.updateNavigation();
            }
            _this.setListPosition();
            _this.setCounter();
        });
        this.nodes.li.forEach(function (item) {
            var links = item.querySelectorAll("a");
            links.forEach(function (link) {
                link.addEventListener("click", function (e) {
                    if (_this.data.isSwipe)
                        e.preventDefault();
                });
            });
            /* (old)
                item.querySelector("a").addEventListener("click", (e) => {
                    if (this.data.isSwipe) e.preventDefault();
                })
            */
        });
        if (this.data.type === "mini") {
            this.nodes.list.addEventListener(core_module_1.Core.getTransitionEnd(), function (e) {
                if (e.propertyName === "filter")
                    return;
                if (_this.data.pageId === 0) {
                    _this.nodes.list.classList.add("no-transition");
                    _this.nodes.list.classList.remove("loop");
                    _this.setListPosition(0);
                }
                if (_this.data.pageId === (_this.data.length - 1)) {
                    _this.nodes.list.classList.add("loop");
                }
                _this.data.isAnimate = false;
            });
        }
        // Swipe
        var hammer = new hammerjs_1.default(this.nodes.items);
        hammer.get('pan').set({ threshold: 1 });
        hammer.on("panstart", function (e) {
            _this.data.isSwipe = true;
        });
        hammer.on("panend", function (e) {
            setTimeout(function () {
                _this.data.isSwipe = false;
            }, 50);
        });
        hammer.on("swipe", function (e) {
            if (e.offsetDirection === 4 && _this.data.type === "default")
                _this.nodes.prev.click();
            if (e.offsetDirection === 2)
                _this.nodes.next.click();
        });
        // Keyboard (default big slider only)
        if (this.data.type === "default") {
            document.addEventListener("keydown", function (e) {
                if (e.keyCode === 39) {
                    _this.nodes.next.click();
                }
                else if (e.keyCode === 37) {
                    _this.nodes.prev.click();
                }
            });
        }
    };
    // Update navigation
    Slider.prototype.updateNavigation = function () {
        if (this.data.type === "mini") {
            if (this.data.length > 1) {
                this.nodes.next.classList.remove("hide");
            }
            else {
                // Rimuovo i margini per centrare il contatore visto 
                // che il bottone "next" è nascosto.
                this.nodes.counter.style.margin = "0";
            }
            return;
        }
        if (this.data.length <= this.getVisibleItems()) {
            this.nodes.prev.classList.add("hide");
            this.nodes.next.classList.add("hide");
            return;
        }
        this.nodes.next.classList.remove("hide");
        this.nodes.prev.classList.remove("hide");
        if (this.data.pageId <= 0) {
            this.nodes.prev.setAttribute("disabled", true);
            this.nodes.next.removeAttribute("disabled");
        }
        else if (this.data.pageId >= this.getTotalPages()) {
            this.nodes.next.setAttribute("disabled", true);
            this.nodes.prev.removeAttribute("disabled");
        }
        else {
            this.nodes.prev.removeAttribute("disabled");
            this.nodes.next.removeAttribute("disabled");
        }
    };
    // Set list width
    Slider.prototype.setListWidth = function () {
        this.nodes.list.style.width = this.getItemWidth() * this.data.length + "px";
    };
    // Set items width
    Slider.prototype.setItemsWidth = function () {
        var width = this.getItemWidth();
        for (var i = 0; i < this.data.length; i++) {
            var item = this.nodes.li[i];
            item.style.width = width + "px";
        }
    };
    // Set list position
    Slider.prototype.setListPosition = function (position) {
        if (position == 0) {
            this.nodes.list.style.webkitTransform = "translateX(0)";
            this.nodes.list.style.transform = "translateX(0)";
            return;
        }
        var x = this.getListPosition();
        this.nodes.list.style.webkitTransform = "translateX(-" + x + "px)";
        this.nodes.list.style.transform = "translateX(-" + x + "px)";
    };
    // Set counter
    Slider.prototype.setCounter = function () {
        var counterData = this.getCounterData();
        this.nodes.counterCurrent.innerHTML = counterData.current;
        this.nodes.counterTotal.innerHTML = counterData.total;
    };
    // Get visible items
    Slider.prototype.getVisibleItems = function () {
        var total = 1;
        if (this.data.type != "mini" && app_component_1.AppData.mediaQuery >= 30 && app_component_1.AppData.mediaQuery < 64)
            total = 2;
        if (this.data.type != "mini" && app_component_1.AppData.mediaQuery >= 64)
            total = 3;
        return total;
    };
    // Get last page items 
    Slider.prototype.getLastPageItems = function () {
        var lastPageItems = this.data.length - (this.getVisibleItems() * this.getTotalPages());
        return this.getVisibleItems() - lastPageItems;
    };
    // Get total pages
    Slider.prototype.getTotalPages = function () {
        return Math.ceil(this.data.length / this.getVisibleItems()) - 1;
    };
    // Get item width
    Slider.prototype.getItemWidth = function () {
        var visibleItems = this.getVisibleItems(), area = this.nodes.items.offsetWidth;
        return (this.data.type === "mini" || visibleItems === 1) ? area : Math.floor(area / visibleItems);
    };
    // Get list position
    Slider.prototype.getListPosition = function () {
        var x = (this.getItemWidth() * this.getVisibleItems()) * this.data.pageId;
        if (this.data.type === "mini") {
            if (this.data.pageId === 0 && !this.nodes.list.classList.contains("no-transition")) {
                return this.getItemWidth() * this.data.length;
            }
        }
        else {
            if (this.data.pageId === this.getTotalPages()) {
                return x - (this.getItemWidth() * this.getLastPageItems());
            }
        }
        return x;
    };
    // Get counter data
    Slider.prototype.getCounterData = function () {
        var to, from, offset;
        if (this.data.type === "mini" || this.getVisibleItems() === 1) {
            offset = this.getNumberPrefix(this.data.pageId + 1);
        }
        else {
            if (this.data.pageId === this.getTotalPages()) {
                to = this.data.length;
            }
            else {
                to = this.getVisibleItems() * (this.data.pageId + 1);
            }
            from = (to - this.getVisibleItems()) + 1;
            offset = this.getNumberPrefix(from) + " &horbar; " + this.getNumberPrefix(to);
        }
        return {
            current: offset,
            total: this.getNumberPrefix(this.data.length)
        };
    };
    // Get number prefix
    Slider.prototype.getNumberPrefix = function (number) {
        return (number <= 9) ? "0" + number : "" + number;
    };
    return Slider;
}());
exports.Slider = Slider;
