"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StoresRoot = void 0;
var app_component_1 = require("../app.component");
var StoresRoot = /** @class */ (function () {
    function StoresRoot() {
        //console.log("StoresRoot constructor");
        var _this = this;
        this.settings = {
            earthRadius: 6371,
            iconStandard: "/assets/img/map-marker-standard.svg",
            iconPriority: "/assets/img/map-marker-priority.svg"
        };
        this.nodes = {};
        this.geolocationPosition = null;
        //
        this._locatorStatus = LocatorStatus.NotSet;
        //
        this.data = null;
        this.markers = null;
        this.markerCluster = null;
        //
        this.rootNode = document.querySelector("*[data-id=\"stores-root\"]");
        this.nodes.locatorMessage = document.querySelector("*[data-id=\"stores-locator-message\"]");
        this.nodes.locatorMessageClose = document.querySelector("*[data-id=\"stores-locator-message-close\"]");
        this.nodes.locator = document.querySelector("*[data-id=\"stores-locator\"]");
        this.nodes.locationInput = document.querySelector("input[data-id=\"stores-location\"]");
        this.nodes.locationReset = document.querySelector("*[data-id=\"stores-location-reset\"]");
        this.nodes.distanceSelect = document.querySelector("select[data-id=\"stores-location-distance\"]");
        this.nodes.distanceReset = document.querySelector("*[data-id=\"stores-location-distance-reset\"]");
        //
        this.nodes.storesItems = document.querySelector("*[data-id=\"stores-items\"]");
        //
        this.nodes.mapPlaceholder = document.querySelector(".map-placeholder");
        //
        //this.nodes.locationInput.addEventListener("keyup", (e: KeyboardEvent) => { });
        this.nodes.locationReset.addEventListener("click", function (e) {
            _this.nodes.locationInput.value = "";
            _this.geolocationPosition = null;
            _this.mapSearch();
            _this.setSelected(null);
        });
        this.nodes.distanceSelect.addEventListener("change", function () {
            _this.mapSearch();
        });
        this.nodes.distanceReset.addEventListener("click", function (e) {
            _this.nodes.distanceSelect.selectedIndex = 0;
            _this.nodes.distanceSelect.dispatchEvent(new Event("change"));
        });
        // event message close
        this.nodes.locatorMessageClose.addEventListener("click", function (e) {
            localStorage.setItem("locator_enabled", "false");
            _this.nodes.locatorMessage.classList.remove("visible");
        });
        // geolocation
        this.nodes.locator.addEventListener("click", function () {
            _this.geolocate();
            _this.setSelected(null);
        });
        // initialize data
        this.data = JSON.parse(document.querySelector("script[data-id=\"stores-data\"]").innerHTML);
        var _loop_1 = function (i) {
            var cursor = this_1.data.stores[i];
            // get item
            var item = this_1.nodes.storesItems.querySelector("*[data-id=\"" + cursor.i.toString() + "\"]");
            if (item === null)
                console.error("Item not found for store with IDnode=" + cursor.i.toString() + ".");
            cursor.node = item;
            // adjust priority
            cursor.priority = (cursor.p === 1);
            // distance
            cursor.distance = undefined;
            // item on mouseenter
            item.addEventListener("mouseenter", function () {
                //console.log("mouseenter", cursor.marker);
                if (cursor.marker === null || cursor.marker === undefined)
                    return;
                cursor.marker.setAnimation(google.maps.Animation.BOUNCE);
                window.clearTimeout(cursor.animationTimeout);
                cursor.animationTimeout = window.setTimeout(function () { cursor.marker.setAnimation(null); }, 750);
            });
            // item on click
            item.addEventListener("click", function () {
                //console.log("click", cursor.marker);
                if (cursor.marker === null || cursor.marker === undefined)
                    return;
                _this.nodes.map.panTo(cursor.marker.getPosition());
                _this.nodes.map.setZoom(17);
                // bounce one
                cursor.marker.setAnimation(google.maps.Animation.BOUNCE);
                window.clearTimeout(cursor.animationTimeout);
                cursor.animationTimeout = window.setTimeout(function () { cursor.marker.setAnimation(null); }, 750);
                // set selected
                _this.setSelected(cursor);
            });
        };
        var this_1 = this;
        for (var i = 0; i < this.data.stores.length; i++) {
            _loop_1(i);
        }
        //console.log(this.data);
    }
    Object.defineProperty(StoresRoot.prototype, "locatorStatus", {
        get: function () {
            if (this._locatorStatus === LocatorStatus.NotSet) {
                var locatorEnabledStoredValue = localStorage.getItem("locator_enabled");
                if (locatorEnabledStoredValue === "true")
                    this._locatorStatus = LocatorStatus.Enabled;
                else if (locatorEnabledStoredValue === "false")
                    this._locatorStatus = LocatorStatus.Disabled;
            }
            //
            return this._locatorStatus;
        },
        set: function (status) {
            this._locatorStatus = status;
            localStorage.setItem("locator_enabled", this._locatorStatus);
        },
        enumerable: false,
        configurable: true
    });
    StoresRoot.prototype.geolocationSuccess = function (position) {
        // set position
        this.geolocationPosition = position;
        //
        this.mapSearch();
    };
    StoresRoot.prototype.geolocationError = function (error) {
        console.error("error", error);
    };
    StoresRoot.prototype.geolocate = function () {
        //console.log("geolocate");
        var _this = this;
        // clear input
        this.nodes.locationInput.value = "";
        // locator
        this.locatorStatus = LocatorStatus.Enabled;
        this.nodes.locatorMessage.classList.remove("visible");
        this.nodes.locator.classList.remove("unknown");
        //
        //var geo_options = {
        //	enableHighAccuracy: true,
        //	maximumAge: 30000,
        //	timeout: 27000
        //};
        var wpid = navigator.geolocation.getCurrentPosition(function (position) { _this.geolocationSuccess(position); }, function (error) { _this.geolocationError(error); }
        //,  geo_options
        );
    };
    Object.defineProperty(StoresRoot.prototype, "distanceValue", {
        get: function () {
            return parseInt(this.nodes.distanceSelect.options[this.nodes.distanceSelect.selectedIndex].value);
        },
        enumerable: false,
        configurable: true
    });
    StoresRoot.prototype.mapsStart = function () {
        //console.log("StoreLocatorMapsStart");
        var _this = this;
        // search text
        this.autocomplete = new google.maps.places.Autocomplete(this.nodes.locationInput);
        this.autocomplete.addListener("place_changed", function () {
            _this.mapSearch();
        });
        // map options
        var mapOptions = {
            zoom: 2,
            minZoom: 2,
            //maxZoom: 18,
            center: new google.maps.LatLng(0.0, 0.0),
            mapTypeControlOptions: {
                mapTypeIds: [google.maps.MapTypeId.ROADMAP, 'map_style']
            }
            //, disableDefaultUI: true
            ,
            mapTypeControl: false
        };
        // render map
        this.nodes.map = this.mapApplyStyle(this.nodes.mapPlaceholder, mapOptions, this.data.tools.map_styles);
        // add markers
        this.mapsRenderMarkers();
        // check locator enabled
        if ("geolocation" in navigator) {
            this.nodes.locatorMessage.classList.toggle("visible", (this.locatorStatus === LocatorStatus.NotSet));
            if (this.locatorStatus === LocatorStatus.Enabled) {
                this.geolocate();
                return;
            }
        }
        else {
            // disabled, hide geolocator
            this.nodes.locator.classList.add("invisible");
        }
    };
    ;
    StoresRoot.prototype.mapSearch = function () {
        //console.log("mapSearch");
        // center
        var center = null;
        if (this.autocomplete != null && this.nodes.locationInput.value.replace(/ /, "") != "") {
            var place = this.autocomplete.getPlace();
            if (place != null) {
                if (!place.geometry) {
                    // User entered the name of a Place that was not suggested and
                    // pressed the Enter key, or the Place Details request failed.
                    //window.alert("No details available for input: '" + place.name + "'");
                    return;
                }
                //// If the place has a geometry, then present it on a map.
                //if (place.geometry.viewport)
                //{
                //	map.fitBounds(place.geometry.viewport);
                //} else
                //{
                //	map.setCenter(place.geometry.location);
                //	map.setZoom(17);  // Why 17? Because it looks good.
                //}
                //marker.setPosition(place.geometry.location);
                //marker.setVisible(true);
                //console.log(place.geometry.location.lat());
                //console.log(place.geometry.location.lng());
                var center = { lat: place.geometry.location.lat(), lng: place.geometry.location.lng() };
                //console.log("center from place", center);
            }
        }
        //
        if (center === null && this.geolocationPosition !== null) {
            center = { lat: this.geolocationPosition.coords.latitude, lng: this.geolocationPosition.coords.longitude };
            //console.log("center from geolocation", center);
        }
        // radius
        var radius = 0;
        if (center !== null)
            radius = this.distanceValue;
        // center and radius, and bounds
        var bound = new google.maps.LatLngBounds();
        if (center != null) {
            for (var i = 0; i < this.data.stores.length; i++) {
                var cursor = this.data.stores[i];
                var mlat = cursor.lt;
                var mlng = cursor.ln;
                var dLat = this.mapsRad(mlat - center.lat);
                var dLong = this.mapsRad(mlng - center.lng);
                var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(this.mapsRad(center.lat)) * Math.cos(this.mapsRad(center.lat)) * Math.sin(dLong / 2) * Math.sin(dLong / 2);
                var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
                var d = this.settings.earthRadius * c;
                // set distance
                cursor.distance = d;
                // order
                var order = Math.round(cursor.distance * 1000);
                if (!cursor.priority)
                    order += 10000;
                cursor.node.style.order = order;
                this.showDistance(cursor);
                //console.log(i, cursor.distance);
                // use marker to get map bounds
                if (d < radius) {
                    // add market to bounds
                    if (cursor.marker !== null)
                        bound.extend(cursor.marker.getPosition());
                }
            }
        }
        else {
            // get all markers
            for (var i = 0; i < this.data.stores.length; i++) {
                var cursor = this.data.stores[i];
                if (cursor.marker !== null)
                    bound.extend(cursor.marker.getPosition());
                // clear distance 
                cursor.distance = undefined;
                // order
                cursor.node.style.order = "";
                this.showDistance(cursor);
            }
        }
        // set bounds
        this.nodes.map.fitBounds(bound);
        // scroll top
        this.nodes.storesItems.scroll({
            top: top,
            behavior: "smooth" //auto, smooth, initial, inherit
        });
    };
    ;
    StoresRoot.prototype.mapsRenderMarkers = function () {
        //console.warn("mapsRenderMarkers:", this.data.stores.length);
        var _this = this;
        // initialize markerCluster
        var clusterStyles = [{
                fontFamily: "Body Regular, Helvetica Neue, Helvetica, Arial, sans-serif",
                textColor: '#000000',
                textSize: 22,
                //style: 'font-family:Body Regular, Helvetica Neue, Helvetica, Arial, sans- serif; font-size:22px;  font-weight:normal; font-style:normal; color:#000;',
                url: app_component_1.AppData.applicationPath + "/assets/img/map-marker-many.svg",
                height: 55,
                width: 55
            }];
        //var mcOptions = { gridSize: 50, styles: clusterStyles, maxZoom: 15 };
        var mcOptions = {
            gridSize: 100,
            styles: clusterStyles,
            maxZoom: 10
        };
        this.markerCluster = new MarkerClusterer(this.nodes.map, [], mcOptions);
        // prepare icons
        var iconStandard = {
            url: app_component_1.AppData.applicationPath + this.settings.iconStandard,
            size: new google.maps.Size(26, 26),
            origin: new google.maps.Point(0, 0),
            anchor: new google.maps.Point(13, 13)
        };
        var iconPriority = {
            url: app_component_1.AppData.applicationPath + this.settings.iconPriority,
            size: new google.maps.Size(26, 26),
            origin: new google.maps.Point(0, 0),
            anchor: new google.maps.Point(13, 13)
        };
        // add markers
        this.markers = this.data.stores.map(function (item, i) {
            // skip items w/out coords
            if (item.lt === 0 && item.lg === 0)
                return null;
            // create marker
            var marker = new google.maps.Marker({
                position: new google.maps.LatLng(item.lt, item.ln),
                title: item.n,
                icon: (item.priority) ? iconPriority : iconStandard,
                //
                item: item
            });
            //
            item.marker = marker;
            //
            marker.addListener("click", function () {
                _this.setSelected(item);
            });
            //
            return marker;
        });
        // set markers
        var bound = new google.maps.LatLngBounds();
        for (var i = 0; i < this.markers.length; i++) {
            var marker = this.markers[i];
            marker.setMap(this.nodes.map);
            bound.extend(marker.getPosition());
        }
        // set zoom and position
        this.nodes.map.fitBounds(bound);
        //
        this.markerCluster.addMarkers(this.markers);
        //if (markers.length != 0)
        //	distributionObj.markerCluster.fitMapToMarkers();
    };
    StoresRoot.prototype.mapsRad = function (x) {
        return x * Math.PI / 180;
    };
    StoresRoot.prototype.mapApplyStyle = function (mapObj, mapOptions, mapStyle) {
        // create style
        var styledMap = new google.maps.StyledMapType(mapStyle, { name: "map" });
        // add options
        var map = new google.maps.Map(mapObj, mapOptions);
        // associate the styled map with the MapTypeId and set it to display.
        map.mapTypes.set('map_style', styledMap);
        map.setMapTypeId('map_style');
        //
        return map;
    };
    ;
    StoresRoot.prototype.mapsItemDetailsClear = function () {
        //console.log("mapsItemDetailsClear not yet developed.");
    };
    ;
    StoresRoot.prototype.mapsItemDetailsShow = function (item) {
        //console.log("mapsItemDetailsShow not yet developed.", item);
    };
    ;
    StoresRoot.prototype.showDistance = function (store) {
        var h3 = store.node.querySelector("h3");
        if (h3 === null)
            return;
        var tag = h3.querySelector("span.distance");
        if (store.distance === undefined) {
            if (tag !== null)
                tag.parentElement.removeChild(tag);
        }
        else {
            if (tag === null) {
                tag = document.createElement("span");
                h3.appendChild(tag);
                tag.classList.add("distance");
            }
            tag.innerText = Math.round(store.distance) + " km";
        }
    };
    StoresRoot.prototype.setSelected = function (store) {
        // cleanup
        for (var i = 0; i < this.data.stores.length; i++)
            this.data.stores[i].node.classList.remove("selected");
        // select
        if (store !== null && store !== undefined) {
            store.node.classList.add("selected");
            // scroll
            var parent_1 = store.node.parentElement;
            var top_1 = store.node.getBoundingClientRect().top - parent_1.getBoundingClientRect().top + parent_1.scrollTop;
            store.node.parentElement.scroll({
                top: top_1,
                behavior: "smooth" //auto, smooth, initial, inherit
            });
        }
    };
    return StoresRoot;
}());
exports.StoresRoot = StoresRoot;
var LocatorStatus;
(function (LocatorStatus) {
    LocatorStatus["NotSet"] = "";
    LocatorStatus["Enabled"] = "true";
    LocatorStatus["Disabled"] = "false";
})(LocatorStatus || (LocatorStatus = {}));
