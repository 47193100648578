"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Context = void 0;
// Imports
var app_component_1 = require("../app.component");
// Context
var Context = /** @class */ (function () {
    function Context(context) {
        this.nodes = {};
        this.nodes.title = context.querySelector(".grid-col:first-child");
        this.nodes.cover = context.querySelector(".grid-col:nth-child(2)");
        this.nodes.text = context.querySelector(".grid-col:last-child");
        this.nodes.content = context.querySelector(".content");
        this.nodes.links = context.querySelector(".links-bottom");
        //this.nodes.washingButton = context.querySelector(".washing-instructions span.text-bold");
        this.nodes.washingButton = context.querySelector(".washing-instructions a.text-bold");
        this.nodes.instructions = context.querySelector(".washing-instructions .instructions");
        if (this.nodes.washingButton) {
            this.washing();
        }
        // washing instructions download: prevent instructions closing
        var buttonInstructionsDownload = context.querySelector(".washing-instructions .instructions a");
        if (buttonInstructionsDownload !== null) {
            buttonInstructionsDownload.addEventListener("click", function (e) {
                e.stopImmediatePropagation();
            });
        }
    }
    // Resize
    Context.prototype.resize = function () {
        if (!this.nodes.links)
            return;
        if (app_component_1.AppData.mediaQuery < 64) {
            this.restore();
            return;
        }
        var titleHeight = this.nodes.title.offsetHeight, coverHeight = this.nodes.cover.offsetHeight, contentHeight = this.nodes.content.offsetHeight, linksHeight = this.nodes.links.offsetHeight;
        if ((contentHeight + linksHeight + 32) < (coverHeight - titleHeight)) {
            this.setHeight(coverHeight - titleHeight);
        }
        else {
            this.restore();
        }
    };
    // Washing
    Context.prototype.washing = function () {
        var _this = this;
        this.nodes.washingButton.addEventListener("click", function () {
            _this.nodes.instructions.classList.add("show");
        });
        this.nodes.instructions.addEventListener("click", function () {
            _this.nodes.instructions.classList.remove("show");
        });
    };
    // Set height
    Context.prototype.setHeight = function (height) {
        this.nodes.links.classList.add("links-sticky");
        this.nodes.text.style.height = height + "px";
    };
    // Restore
    Context.prototype.restore = function () {
        this.nodes.links.classList.remove("links-sticky");
        this.nodes.text.removeAttribute("style");
    };
    return Context;
}());
exports.Context = Context;
