"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Bundle = void 0;
// Bundle nodes
var BundleNodes = {
    root: document.querySelector(".bundle")
};
// Bundle
var Bundle = /** @class */ (function () {
    // Constructor
    function Bundle() {
        if (BundleNodes.root) {
            // console.log("Bundle");
        }
    }
    return Bundle;
}());
exports.Bundle = Bundle;
