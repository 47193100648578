"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CallToAction = void 0;
var Fetcher_1 = require("../common/Fetcher");
// Context
var CallToAction = /** @class */ (function () {
    function CallToAction(context) {
        //console.log("CallToAction.construction");
        var _this = this;
        this.searchMode = SearchMode.Basic;
        this.nodes = {};
        //
        this.resultsLoadMode = ResultsLoadMode.XHR;
        this.resultsLoadDelay = 100;
        this.resultsLoadFetcher = null;
        // all selects start disabled, asap they are enabled
        // load data
        this.data = JSON.parse(document.querySelector("script[data-id=\"products-search-data\"]").innerHTML);
        //console.log("data", this.data);
        // load template
        this.productThumbTemplate = document.querySelector("template#product-item");
        // html nodes
        this.context = context;
        this.nodes.selector = context.querySelector(".selector");
        // BASIC
        this.nodes.basic = this.nodes.selector.querySelector("*[data-id=\"products-search-basic\"]");
        this.nodes.basicTitle = this.nodes.basic.querySelector(".title");
        //
        this.nodes.basicRootsSelect = this.nodes.basic.querySelector("select[data-id=\"products-search-basic-roots\"]");
        this.nodes.basicRootsClear = this.nodes.basic.querySelector("*[data-id=\"products-search-basic-roots-reset\"]");
        //
        this.nodes.basicValuesSelect = this.nodes.basic.querySelector("select[data-id=\"products-search-basic-values\"]");
        this.nodes.basicValuesClear = this.nodes.basic.querySelector("*[data-id=\"products-search-basic-values-reset\"]");
        // ADVANCED
        this.nodes.advanced = this.nodes.selector.querySelector("*[data-id=\"products-search-advanced\"]");
        this.nodes.advancedTitle = this.nodes.advanced.querySelector(".title");
        //
        this.nodes.advancedTypeSelect = this.nodes.advanced.querySelector("select[data-id=\"products-search-advanced-type\"]");
        this.nodes.advancedTypeClear = this.nodes.advanced.querySelector("*[data-id=\"products-search-advanced-type-reset\"]");
        //
        this.nodes.advancedSubtypeSelect = this.nodes.advanced.querySelector("select[data-id=\"products-search-advanced-subtype\"]");
        this.nodes.advancedSubtypeClear = this.nodes.advanced.querySelector("*[data-id=\"products-search-advanced-subtype-reset\"]");
        //
        this.nodes.advancedColorSelect = this.nodes.advanced.querySelector("select[data-id=\"products-search-advanced-color\"]");
        this.nodes.advancedColorClear = this.nodes.advanced.querySelector("*[data-id=\"products-search-advanced-color-reset\"]");
        //
        this.nodes.advancedCollectionSelect = this.nodes.advanced.querySelector("select[data-id=\"products-search-advanced-collection\"]");
        this.nodes.advancedCollectionClear = this.nodes.advanced.querySelector("*[data-id=\"products-search-advanced-collection-reset\"]");
        //
        this.nodes.advancedMaterialSelect = this.nodes.advanced.querySelector("select[data-id=\"products-search-advanced-material\"]");
        this.nodes.advancedMaterialClear = this.nodes.advanced.querySelector("*[data-id=\"products-search-advanced-material-reset\"]");
        //
        this.nodes.advancedYearSelect = this.nodes.advanced.querySelector("select[data-id=\"products-search-advanced-year\"]");
        this.nodes.advancedYearClear = this.nodes.advanced.querySelector("*[data-id=\"products-search-advanced-year-reset\"]");
        //
        this.nodes.advancedDesignerSelect = this.nodes.advanced.querySelector("select[data-id=\"products-search-advanced-designer\"]");
        this.nodes.advancedDesignerClear = this.nodes.advanced.querySelector("*[data-id=\"products-search-advanced-designer-reset\"]");
        // order-by
        this.nodes.orderBySelect = this.context.querySelector("select[data-id=\"order-by\"]");
        // loading
        this.nodes.loading = this.context.querySelector("*[data-id=\"products-loading\"]");
        // results
        this.nodes.resultsPlaceholder = document.querySelector("*[data-id=\"products-results\"]");
        // selector events
        this.nodes.basicTitle.addEventListener("click", function (e) { _this.toggleSearchMode(); });
        this.nodes.advancedTitle.addEventListener("click", function (e) { _this.toggleSearchMode(); });
        // basic select events
        this.nodes.basicRootsSelect.addEventListener("change", function (e) { _this.basicRootChange(); });
        this.nodes.basicRootsClear.addEventListener("click", function (e) { _this.clearSelection(e); });
        this.nodes.basicValuesSelect.addEventListener("change", function (e) { _this.basicSubmit(); });
        this.nodes.basicValuesClear.addEventListener("click", function (e) { _this.clearSelection(e); });
        // advanced select events
        this.nodes.advancedTypeSelect.addEventListener("change", function (e) { _this.advancedFillSubTypes(); _this.advancedSubmit(); });
        this.nodes.advancedTypeClear.addEventListener("click", function (e) { _this.clearSelection(e); });
        //
        this.nodes.advancedSubtypeSelect.addEventListener("change", function (e) { _this.advancedSubmit(); });
        this.nodes.advancedSubtypeClear.addEventListener("click", function (e) { _this.clearSelection(e); });
        //
        this.nodes.advancedColorSelect.addEventListener("change", function (e) { _this.advancedSubmit(); });
        this.nodes.advancedColorClear.addEventListener("click", function (e) { _this.clearSelection(e); });
        //
        this.nodes.advancedCollectionSelect.addEventListener("change", function (e) { _this.advancedSubmit(); });
        this.nodes.advancedCollectionClear.addEventListener("click", function (e) { _this.clearSelection(e); });
        //
        this.nodes.advancedMaterialSelect.addEventListener("change", function (e) { _this.advancedSubmit(); });
        this.nodes.advancedMaterialClear.addEventListener("click", function (e) { _this.clearSelection(e); });
        //
        if (this.nodes.advancedYearSelect != null) {
            this.nodes.advancedYearSelect.addEventListener("change", function (e) { _this.advancedSubmit(); });
            this.nodes.advancedYearClear.addEventListener("click", function (e) { _this.clearSelection(e); });
        }
        //
        this.nodes.advancedDesignerSelect.addEventListener("change", function (e) { _this.advancedSubmit(); });
        this.nodes.advancedDesignerClear.addEventListener("click", function (e) { _this.clearSelection(e); });
        // order-by event
        this.nodes.orderBySelect = this.context.querySelector("select[data-id=\"order-by\"]");
        this.nodes.orderBySelect.addEventListener("change", function () { _this.resultsReload(); });
        // set starting search mode (basic/advanced)
        if (this.nodes.selector.classList.contains("advanced"))
            this.searchMode = SearchMode.Advanced;
        // enable all selects
        // basic
        this.nodes.basicRootsSelect.disabled = false;
        this.nodes.basicValuesSelect.disabled = false;
        // advanced
        this.nodes.advancedTypeSelect.disabled = false;
        this.nodes.advancedSubtypeSelect.disabled = false;
        this.nodes.advancedColorSelect.disabled = false;
        this.nodes.advancedCollectionSelect.disabled = false;
        this.nodes.advancedMaterialSelect.disabled = false;
        if (this.nodes.advancedYearSelect != null)
            this.nodes.advancedYearSelect.disabled = false;
        this.nodes.advancedDesignerSelect.disabled = false;
        // order-by
        this.nodes.orderBySelect.disabled = false;
        // initialize imagesLoader
        imagesLoader.context = this.nodes.resultsPlaceholder;
        imagesLoader.query = "img[data-src]";
        imagesLoader.execute();
        // url management
        window.addEventListener("popstate", function (e) {
            _this.popstate(e);
        });
    }
    Object.defineProperty(CallToAction.prototype, "loadingVisible", {
        //
        get: function () {
            return this.nodes.loading.classList.contains("visible");
        },
        set: function (visible) {
            this.nodes.loading.classList.toggle("visible", visible);
        },
        enumerable: false,
        configurable: true
    });
    CallToAction.prototype.popstate = function (e) {
        // check
        if (e.state === null)
            return;
        // adjust search mode (page title is automated into SearchMode change)
        if (e.state.searchMode !== undefined) {
            var stateSearchMode = e.state.searchMode;
            if (this.searchMode !== stateSearchMode)
                this.toggleSearchMode();
        }
        // breadcrumbs
        this.setBreadcrumbsLast(this.searchModeRelatedPageData.name);
        // selects
        if (this.searchMode === SearchMode.Basic) {
            // basic selects
            for (var p in e.state) {
                if (e.state.hasOwnProperty(p) && p != "sort" && p != "data" && p != "searchMode") {
                    this.selectValueSet(this.nodes.basicRootsSelect, p);
                    this.nodes.basicRootsSelect.dispatchEvent(new Event("change"));
                    this.selectValueSet(this.nodes.basicValuesSelect, e.state[p]);
                    break;
                }
            }
        }
        else {
            // advanced selects
            for (var p in e.state) {
                if (e.state.hasOwnProperty(p) && p != "sort" && p != "data" && p != "searchMode") {
                    //
                    var value = e.state[p];
                    switch (p) {
                        case "type":
                            this.selectValueSet(this.nodes.advancedTypeSelect, value);
                            this.advancedFillSubTypes();
                            break;
                        case "subtype":
                            this.selectValueSet(this.nodes.advancedSubtypeSelect, value);
                            break;
                        case "color":
                            this.selectValueSet(this.nodes.advancedColorSelect, value);
                            break;
                        case "collection":
                            this.selectValueSet(this.nodes.advancedCollectionSelect, value);
                            break;
                        case "material":
                            this.selectValueSet(this.nodes.advancedMaterialSelect, value);
                            break;
                        case "year":
                            this.selectValueSet(this.nodes.advancedYearSelect, value);
                            break;
                        case "designer":
                            this.selectValueSet(this.nodes.advancedDesignerSelect, value);
                            break;
                        default:
                            console.warn("popstate e.state parameter not found " + p);
                            break;
                    }
                }
            }
        }
        // sort mode
        if (e.state.sort !== undefined)
            this.sortMode = e.state.sort;
        // render items
        this.resultsRender(e.state.data);
        imagesLoader.execute();
    };
    // resize
    CallToAction.prototype.resize = function () {
        //console.log("CallToAction.resize");
        imagesLoader.execute();
    };
    // scroll
    CallToAction.prototype.scroll = function () {
        //console.log("CallToAction.scroll");
        imagesLoader.execute();
    };
    // toggle SearchMode
    CallToAction.prototype.toggleSearchMode = function () {
        //
        if (this.searchMode === SearchMode.Basic) {
            this.nodes.selector.classList.remove("basic");
            this.nodes.selector.classList.add("advanced");
            this.searchMode = SearchMode.Advanced;
        }
        else {
            this.nodes.selector.classList.remove("advanced");
            this.nodes.selector.classList.add("basic");
            this.searchMode = SearchMode.Basic;
        }
        // page title
        document.title = this.searchModeRelatedPageData.title;
    };
    // basic
    CallToAction.prototype.basicSubmit = function (forceReload) {
        if (forceReload === void 0) { forceReload = false; }
        //
        var rootValue = this.nodes.basicRootsSelect.options[this.nodes.basicRootsSelect.selectedIndex].value;
        var valueValue = "";
        if (this.nodes.basicValuesSelect.options.length !== 0 && this.nodes.basicValuesSelect.selectedIndex !== 0)
            valueValue = this.nodes.basicValuesSelect.options[this.nodes.basicValuesSelect.selectedIndex].value;
        //
        if ((rootValue !== "" && valueValue !== "") || forceReload === true) {
            var parameters = {};
            if (rootValue !== "" && valueValue !== "")
                parameters[rootValue] = valueValue;
            //console.log("parameters", parameters);
            this.generalSubmit(parameters, forceReload);
        }
    };
    CallToAction.prototype.basicRootChange = function () {
        var rootName = this.nodes.basicRootsSelect.options[this.nodes.basicRootsSelect.selectedIndex].value;
        //console.log(rootName);
        // cleanup select values
        this.optionsClear(this.nodes.basicValuesSelect);
        //
        if (rootName !== "") {
            var select = this.context.querySelector("select[data-id=\"products-search-advanced-" + rootName + "\"]");
            if (select !== null) {
                this.optionsClone(select, this.nodes.basicValuesSelect);
            }
        }
    };
    // advanced
    CallToAction.prototype.advancedSubmit = function (forceReload) {
        if (forceReload === void 0) { forceReload = false; }
        //
        var parameters = {};
        //
        var IDtype = this.selectValueGet(this.nodes.advancedTypeSelect);
        if (IDtype !== null && IDtype !== "")
            parameters.type = IDtype;
        //
        var IDsubType = this.selectValueGet(this.nodes.advancedSubtypeSelect);
        if (IDsubType !== null && IDsubType !== "")
            parameters.subtype = IDsubType;
        //
        var IDcolor = this.selectValueGet(this.nodes.advancedColorSelect);
        if (IDcolor !== null && IDcolor !== "")
            parameters.color = IDcolor;
        //
        var IDcollection = this.selectValueGet(this.nodes.advancedCollectionSelect);
        if (IDcollection !== null && IDcollection !== "")
            parameters.collection = IDcollection;
        //
        var IDmaterial = this.selectValueGet(this.nodes.advancedMaterialSelect);
        if (IDmaterial !== null && IDmaterial !== "")
            parameters.material = IDmaterial;
        //
        var IDyear = this.selectValueGet(this.nodes.advancedYearSelect);
        if (IDyear !== null && IDyear !== "")
            parameters.year = IDyear;
        //
        var IDdesigner = this.selectValueGet(this.nodes.advancedDesignerSelect);
        if (IDdesigner !== null && IDdesigner !== "")
            parameters.designer = IDdesigner;
        // execute request
        this.generalSubmit(parameters, forceReload);
    };
    CallToAction.prototype.advancedFillSubTypes = function () {
        //console.log("advancedFillSubTypes");
        var IDtype = this.nodes.advancedTypeSelect.options[this.nodes.advancedTypeSelect.selectedIndex].value;
        // clear subtypes
        this.optionsClear(this.nodes.advancedSubtypeSelect);
        // get type from data
        var typeData = this.dataGetType(IDtype);
        if (typeData !== null) {
            // default
            this.optionAdd(this.nodes.advancedSubtypeSelect, this.data.language.select, "");
            // options
            for (var i = 0; i < typeData.st.length; i++) {
                var subtype = typeData.st[i];
                this.optionAdd(this.nodes.advancedSubtypeSelect, subtype.n, subtype.i);
            }
        }
    };
    // general
    CallToAction.prototype.generalSubmit = function (parameters, forceReload) {
        var _this = this;
        if (forceReload === void 0) { forceReload = false; }
        // build querystring
        var querystring = "";
        for (var prop in parameters) {
            if (parameters.hasOwnProperty(prop))
                querystring += "&" + prop + "=" + parameters[prop];
        }
        if (querystring === "" && forceReload == false)
            return;
        // add sorting
        querystring += "&sort=" + this.sortMode;
        querystring = querystring.substring(1);
        //
        var pageURL = this.searchModeRelatedPageData.url;
        var finalURL = pageURL + "?" + querystring;
        if (this.resultsLoadMode === ResultsLoadMode.Redirect) {
            document.location.href = finalURL;
            //console.log("redirect");
        }
        else {
            //
            //finalURL += "&mode=xhr";
            //console.log("load XHR", finalURL);
            // loading
            this.loadingVisible = false;
            // abort
            if (this.resultsLoadFetcher !== null)
                this.resultsLoadFetcher.abort();
            // clear timeout
            window.clearTimeout(this.resultsLoadTimeout);
            // state object
            var state_1 = Object.assign({}, parameters);
            state_1.searchMode = this.searchMode;
            state_1.sort = this.sortMode;
            // set timeout with proper delay
            this.resultsLoadTimeout = window.setTimeout(function () {
                // start loading
                _this.resultsLoadFetcher = new Fetcher_1.Fetcher(finalURL + "&mode=xhr", function (data) {
                    _this.loadingVisible = false;
                    // TODO: manage URL, page title and bred crumbs
                    //console.warn(":: ", finalURL);
                    state_1.data = data;
                    history.pushState(state_1, "page title", finalURL);
                    // render
                    _this.resultsRender(data);
                    imagesLoader.execute();
                }, function (status, data, message) {
                    _this.loadingVisible = false;
                    console.error("error", status, data, message);
                }).send();
                // loading
                _this.loadingVisible = true;
            }, this.resultsLoadDelay);
        }
    };
    CallToAction.prototype.clearSelection = function (e) {
        var target = e.currentTarget;
        var IDselect = target.dataset.id.substring(0, target.dataset.id.lastIndexOf("-"));
        var select = this.context.querySelector("select[data-id=\"" + IDselect + "\"]");
        if (select !== null && select.options.length != 0) {
            select.selectedIndex = 0;
            select.dispatchEvent(new Event('change'));
        }
    };
    CallToAction.prototype.resultsReload = function () {
        //console.log("resultsReload");
        if (this.searchMode === SearchMode.Basic)
            this.basicSubmit(true);
        else
            this.advancedSubmit(true);
    };
    CallToAction.prototype.resultsClear = function () {
        //console.log("resultsClear");
        while (this.nodes.resultsPlaceholder.hasChildNodes())
            this.nodes.resultsPlaceholder.removeChild(this.nodes.resultsPlaceholder.firstChild);
    };
    CallToAction.prototype.resultsRender = function (data) {
        //console.log("resultsRender", data);
        // clear results
        this.resultsClear();
        // render no results
        if (data.products == null || data.products.length == 0) {
            var li = document.createElement("li");
            this.nodes.resultsPlaceholder.appendChild(li);
            li.classList.add("grid-col", "no-results");
            li.appendChild(document.createTextNode(this.data.language.no_results));
            return;
        }
        // render results
        for (var i = 0; i < data.products.length; i++) {
            var product = data.products[i];
            //
            var productThumb = this.populateTemplate(product);
            this.nodes.resultsPlaceholder.appendChild(productThumb);
        }
    };
    Object.defineProperty(CallToAction.prototype, "sortMode", {
        // utilities
        get: function () {
            return this.selectValueGet(this.nodes.orderBySelect);
        },
        set: function (value) {
            this.selectValueSet(this.nodes.orderBySelect, value);
        },
        enumerable: false,
        configurable: true
    });
    CallToAction.prototype.selectValueGet = function (select) {
        if (select !== null && select.options.length != 0)
            return select.options[select.selectedIndex].value;
        return null;
    };
    CallToAction.prototype.selectValueSet = function (select, value) {
        for (var i = 0; i < select.options.length; i++)
            if (select.options[i].value === value) {
                select.selectedIndex = i;
                return true;
            }
        //
        return false;
    };
    CallToAction.prototype.optionsClone = function (selectFrom, selectTo) {
        // cleanup target select
        while (selectTo.hasChildNodes())
            selectTo.removeChild(selectTo.firstChild);
        // clone
        var options = selectFrom.querySelectorAll("option");
        for (var i = 0; i < options.length; i++) {
            var option = options[i];
            selectTo.appendChild(option.cloneNode(true));
        }
    };
    CallToAction.prototype.optionsClear = function (select) {
        while (select.hasChildNodes())
            select.removeChild(select.firstChild);
    };
    CallToAction.prototype.optionAdd = function (select, text, value) {
        var option = document.createElement("option");
        select.appendChild(option);
        option.setAttribute("value", value);
        option.innerText = text;
    };
    CallToAction.prototype.dataGetType = function (IDtype) {
        var types = this.data.types;
        for (var i = 0; i < types.length; i++) {
            if (types[i].i === IDtype)
                return types[i];
        }
        return null;
    };
    CallToAction.prototype.populateTemplate = function (product) {
        //
        var element = this.productThumbTemplate.content.cloneNode(true);
        // a/img
        var a_img = element.querySelector("div.cover a");
        a_img.href = product.u;
        // img
        var img = a_img.querySelector("img");
        //
        var width = parseInt(img.getAttribute("width"));
        var height = parseInt(img.getAttribute("height"));
        var crop = (img.getAttribute("crop") == "true");
        //
        img.removeAttribute("width");
        img.removeAttribute("height");
        img.removeAttribute("crop");
        //
        var imageURL = product.i;
        if (imageURL === null || imageURL === undefined)
            imageURL = this.data.tools.missing_image;
        // fix image dimensions
        imageURL = imageURL.replace(/\/(\d+)x(\d+)\//, "/" + width.toString() + "x" + height.toString() + "/");
        if (crop)
            imageURL = imageURL.replace("/\nocrop\/", "/crop/");
        else
            imageURL = imageURL.replace("/\crop\/", "/nocrop/");
        img.dataset.src = imageURL;
        // name
        var a_name = element.querySelector("*[data-id=\"name\"]");
        a_name.innerText = product.n;
        a_name.href = product.u;
        // collection
        var collectionSpan = element.querySelector("*[data-id=\"collection\"] span");
        collectionSpan.innerText = this.data.language.collection;
        var collectionA = element.querySelector("*[data-id=\"collection\"] a");
        collectionA.innerText = product.cn;
        collectionA.href = product.cu;
        // designer
        var designDiv = element.querySelector("*[data-id=\"design\"]");
        if (product.ds === null || product.ds === undefined || product.ds.length === 0)
            designDiv.remove();
        else {
            var collectionSpan_1 = designDiv.querySelector("span");
            collectionSpan_1.innerText = this.data.language.design;
            //
            var designerA = designDiv.querySelector("a");
            if (designerA !== null) {
                designerA.innerText = "";
                for (var i = 0; i < product.ds.length; i++) {
                    var designerAcloned = designerA.cloneNode(true);
                    designerA.parentElement.appendChild(designerAcloned);
                    //
                    designerAcloned.innerText = product.ds[i].n;
                    designerAcloned.href = product.ds[i].u;
                }
                designerA.parentElement.removeChild(designerA);
            }
        }
        //
        return element;
    };
    CallToAction.prototype.setBreadcrumbsLast = function (value) {
        var lastBreadCrumb = this.context.querySelector("ul.breadcrumbs li:last-child span");
        if (lastBreadCrumb !== null)
            lastBreadCrumb.innerText = value;
    };
    Object.defineProperty(CallToAction.prototype, "searchModeRelatedPageData", {
        get: function () {
            return (this.searchMode === SearchMode.Basic) ? this.data.pages.basic : this.data.pages.advanced;
        },
        enumerable: false,
        configurable: true
    });
    return CallToAction;
}());
exports.CallToAction = CallToAction;
var SearchMode;
(function (SearchMode) {
    SearchMode[SearchMode["Basic"] = 0] = "Basic";
    SearchMode[SearchMode["Advanced"] = 1] = "Advanced";
})(SearchMode || (SearchMode = {}));
var ResultsLoadMode;
(function (ResultsLoadMode) {
    ResultsLoadMode[ResultsLoadMode["XHR"] = 0] = "XHR";
    ResultsLoadMode[ResultsLoadMode["Redirect"] = 1] = "Redirect";
})(ResultsLoadMode || (ResultsLoadMode = {}));
var imagesLoader = /** @class */ (function () {
    function imagesLoader() {
    }
    imagesLoader.execute = function () {
        //
        if (imagesLoader.context === null || imagesLoader.query === null)
            return;
        //
        window.clearTimeout(imagesLoader.timeout);
        imagesLoader.timeout = window.setTimeout(function () { imagesLoader.executeInternal(); }, imagesLoader.delay);
    };
    imagesLoader.executeInternal = function () {
        //console.warn("imagesLoader.executeInternal / " + imagesLoader.timeout);
        // QUEUE
        var tempNodes = imagesLoader.context.querySelectorAll(imagesLoader.query);
        imagesLoader.queue = [];
        //console.log("total: " + tempNodes.length);
        tempNodes.forEach(function (element) {
            var visible = imagesLoader.isIntoView(element);
            if (visible)
                imagesLoader.queue.push(element);
        });
        //
        //console.warn("imagesLoader: " + imagesLoader.queue.length + " images to load");
        // START
        imagesLoader.executeLoadNext();
    };
    imagesLoader.executeLoadNext = function () {
        //console.warn("imagesLoader.executeLoadNext", imagesLoader.queue.length);
        //
        if (imagesLoader.queue == null || imagesLoader.queue.length == 0)
            return;
        // GET FIRST
        var firstImage = imagesLoader.queue[0];
        var dataSrc = firstImage.dataset.src;
        if (dataSrc !== undefined) {
            //
            firstImage.removeAttribute("data-src");
            // ONLOAD
            firstImage.addEventListener("load", function () {
                firstImage.classList.remove("loading");
                imagesLoader.queue.shift();
                imagesLoader.executeLoadNext();
            });
            // ONERROR
            firstImage.addEventListener("error", function () {
                //console.warn("image " + dataSrc + " load error");
                //
                firstImage.classList.remove("loading");
                imagesLoader.queue.shift();
                imagesLoader.executeLoadNext();
            });
            // LOAD
            firstImage.src = dataSrc;
            //
            return;
        }
        //
        imagesLoader.queue.shift();
        imagesLoader.executeLoadNext();
    };
    imagesLoader.isIntoView = function (el) {
        var rect = el.getBoundingClientRect();
        var elemTop = rect.top;
        var elemBottom = rect.bottom;
        // Only completely visible elements return true:
        //var isVisible = (elemTop >= 0) && (elemBottom <= window.innerHeight);
        var isVisible = (elemTop >= 0) && (elemBottom <= (window.innerHeight * 2));
        if (isVisible)
            return true;
        // Partially visible elements return true:
        isVisible = elemTop < window.innerHeight && elemBottom >= 0;
        //
        return isVisible;
    };
    imagesLoader.queue = null;
    imagesLoader.delay = 50; // milliseconds
    imagesLoader.timeout = null;
    //
    imagesLoader.context = null;
    imagesLoader.query = null;
    return imagesLoader;
}());
