"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Fetcher = void 0;
var Fetcher = /** @class */ (function () {
    //
    function Fetcher(url, onSuccess, onError) {
        var _this = this;
        this.onSuccess = onSuccess;
        this.onError = onError;
        this.formData = new FormData();
        //
        this.xhr = new XMLHttpRequest();
        this.xhr.onreadystatechange = function (e) {
            if (_this.xhr.readyState == XMLHttpRequest.DONE) {
                if (_this.xhr.status == 200 && _this.xhr.responseText != "undefined") {
                    // SUCCESS
                    if (_this.onSuccess !== undefined)
                        _this.onSuccess(JSON.parse(_this.xhr.responseText));
                }
                else {
                    // ERROR
                    if (_this.onError !== undefined) {
                        var jsonValue = undefined;
                        var message = undefined;
                        try {
                            jsonValue = JSON.parse(_this.xhr.responseText);
                            message = jsonValue.message;
                        }
                        catch (e) { }
                        _this.onError(_this.xhr.status, jsonValue, message);
                    }
                }
            }
        };
        this.xhr.upload.addEventListener("progress", function (e) {
            var percentage = Math.ceil((e.loaded * 100) / e.total);
            if (_this._onProgress !== undefined)
                _this._onProgress(percentage, e);
        });
        //
        this.xhr.open("POST", url, true);
    }
    Fetcher.prototype.abort = function () {
        this.xhr.abort();
        return this;
    };
    Fetcher.prototype.onProgress = function (onProgress) {
        this._onProgress = onProgress;
        return this;
    };
    Fetcher.prototype.append = function (key, value) {
        if (key === undefined || value === undefined)
            return this;
        //
        this.formData.append(key, value);
        return this;
    };
    Fetcher.prototype.delete = function (key) {
        this.formData.delete(key);
        return this;
    };
    Fetcher.prototype.get = function (key) {
        return this.formData.get(key);
    };
    Fetcher.prototype.getAll = function (key) {
        return this.formData.getAll(key);
    };
    Fetcher.prototype.has = function (key) {
        return this.formData.has(key);
    };
    Fetcher.prototype.send = function () {
        if (this.formData === undefined)
            this.xhr.send();
        else
            this.xhr.send(this.formData);
        return this;
    };
    return Fetcher;
}());
exports.Fetcher = Fetcher;
