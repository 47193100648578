"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Core = void 0;
// Core
var Core = /** @class */ (function () {
    function Core() {
    }
    // Get document size
    Core.getDocumentSize = function () {
        return document.documentElement.getBoundingClientRect();
    };
    // Get window size
    Core.getWindowSize = function () {
        return {
            width: window.innerWidth,
            height: window.innerHeight
        };
    };
    // Get media query
    Core.getMediaQuery = function () {
        var mediaQuery;
        for (var i in Core.mediaQueries) {
            if (window.matchMedia && window.matchMedia("screen and (min-width: " + i + ")").matches) {
                mediaQuery = parseInt(i);
            }
        }
        if (mediaQuery == null) {
            mediaQuery = 0;
        }
        return mediaQuery;
    };
    // Get size
    Core.getSize = function () {
        var mediaQuery = Core.getMediaQuery() + "em";
        return (parseInt(mediaQuery) === 0) ? "xs" : Core.mediaQueries[mediaQuery];
    };
    // Get orientation
    Core.getOrientation = function () {
        var orientation = "portrait";
        if (window.matchMedia("(orientation: landscape)").matches) {
            orientation = "landscape";
        }
        return orientation;
    };
    // Get scroll top
    Core.getScrollTop = function (element) {
        var top = (element != null) ? element.scrollTop : window.pageYOffset;
        return (top < 0) ? 0 : top;
    };
    // Get scroll bottom
    Core.getScrollBottom = function (element, elementContent) {
        var height = (elementContent) ? elementContent.offsetHeight : Core.getDocumentSize()["height"];
        var bottom = (height - Core.getWindowSize()["height"]) - Core.getScrollTop(element);
        return (bottom < 0) ? 0 : bottom;
    };
    // Get scroll perc
    Core.getScrollPerc = function (element, elementContent) {
        var height = (!element) ? Core.getDocumentSize()["height"] : elementContent.offsetHeight;
        return Core.getScrollTop(element) / (height - Core.getWindowSize()["height"]);
    };
    // Get scroll direction
    Core.getScrollDirection = function (element) {
        var direction = (Core.getScrollTop(element) > Core.lastScrollPosition) ? "down" : "up";
        Core.lastScrollPosition = Core.getScrollTop(element);
        return direction;
    };
    // Get transition end
    Core.getTransitionEnd = function () {
        var el = document.createElement("fakeelement");
        var transitions = {
            "transition": "transitionend",
            "OTransition": "oTransitionEnd",
            "MozTransition": "transitionend",
            "WebkitTransition": "webkitTransitionEnd"
        };
        var t = "";
        for (t in transitions) {
            if (el.style[t] !== undefined) {
                return transitions[t];
            }
        }
    };
    // Get animation end
    Core.getAnimationEnd = function () {
        var el = document.createElement("fakeelement");
        var animations = {
            "animation": "animationend",
            "OAnimation": "oAnimationEnd",
            "MozAnimation": "animationend",
            "WebkitAnimation": "webkitAnimationEnd"
        };
        var t = "";
        for (t in animations) {
            if (el.style[t] !== undefined) {
                return animations[t];
            }
        }
    };
    Core.mediaQueries = {
        "30em": "s",
        "48em": "m",
        "64em": "l",
        "80em": "xl",
        "90em": "xxl",
        "120em": "xxxl"
    };
    return Core;
}());
exports.Core = Core;
