"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Gift = void 0;
// Imports
var Fetcher_1 = require("../common/Fetcher");
// Gift nodes
var GiftNodes = {
    root: document.querySelector(".gift"),
    form: document.querySelector(".gift form"),
    fields: document.querySelectorAll(".gift [name]"),
    submit: document.querySelector(".gift button"),
    upload: document.querySelector(".gift input[type='file']")
};
// Gift
var Gift = /** @class */ (function () {
    // Constructor
    function Gift() {
        this.emailRegex = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        this.phoneRegex = /^[0-9\s]+$/;
        this.isLoading = false;
        //private hasErrors: boolean = false;
        this.values = {};
        if (GiftNodes.root) {
            this.listeners();
        }
    }
    // Listeners
    Gift.prototype.listeners = function () {
        var _this = this;
        GiftNodes.form.addEventListener("submit", function (e) {
            e.preventDefault();
            //if (!this.validate() || this.isLoading || this.hasErrors) return;
            //if (this.isLoading || this.hasErrors) return;
            if (_this.isLoading)
                return;
            //console.log(!this.validate());
            // remove errors
            _this.errorsRemove();
            // do it
            _this.submit();
        });
        GiftNodes.fields.forEach(function (field) {
            if (field.type != "submit") {
                var eventType = (field.type == "checkbox" || field.type == "file") ? "change" : "keyup";
                _this.values[field.name] = null;
                field.addEventListener(eventType, function (e) {
                    var fieldValue;
                    if (field.type === "file") {
                        var field_1 = e.target, label = field_1.parentNode.querySelector("span"), value = (field_1.value == "") ? label.getAttribute("data-label") : field_1.files.item(0).name;
                        label.innerHTML = fieldValue = value;
                    }
                    else if (field.type === "checkbox") {
                        fieldValue = field.checked;
                    }
                    else {
                        fieldValue = field.value;
                    }
                    _this.values[field.name] = fieldValue;
                    console.log(_this.values);
                });
            }
        });
    };
    // Serialize
    Gift.prototype.serialize = function () {
        var formData = new FormData(), i = 0;
        for (; i < GiftNodes.fields.length; i++) {
            var field = GiftNodes.fields[i];
            if (field.type === "checkbox" || field.type === "radio") {
                formData.append(field.name, (field.checked == false) ? null : field.value);
            }
            else {
                if (field.type === "file") {
                    if (field.files.length !== 0) {
                        formData.append(field.name, field.files[0]);
                    }
                }
                else {
                    formData.append(field.name, field.value);
                }
            }
        }
        return formData;
    };
    // Validate
    Gift.prototype.validate = function () {
        for (var key in this.values) {
            if ((key === "firstname" || key === "lastname" || key === "message") && !this.checkText(this.values[key]))
                return false;
            if (key === "email" && this.checkEmail(this.values[key]))
                return false;
            if (key === "phone" && this.checkPhone(this.values[key]))
                return false;
            if (key === "phone" && this.checkPhone(this.values[key]))
                return false;
            if (key === "privacy" && (!this.values[key]))
                return false;
            /*if (requiredFields.indexOf(key) != -1 ) {
                console.log("Check text");
            }*/
            //if (this.values.hasOwnProperty(key)) {
            console.log(key + " : " + this.values[key]);
            //}
        }
        return true;
    };
    // Send
    Gift.prototype.submit = function () {
        var _this = this;
        this.isLoading = true;
        GiftNodes.submit.classList.add("loading");
        var url = document.location.href + "?mode=xhr", fetcher = new Fetcher_1.Fetcher(url, function (data) {
            _this.isLoading = false;
            // hide loading
            GiftNodes.submit.classList.remove("loading");
            // some logging
            console.log("Success");
            console.log(data);
            // hide form
            GiftNodes.form.style.display = "none";
            // render message
            var containerMessage = document.createElement("div");
            GiftNodes.form.parentElement.insertBefore(containerMessage, GiftNodes.form);
            containerMessage.classList.add("success-message");
            containerMessage.innerText = data.success;
        }, function (status, data) {
            _this.isLoading = false;
            GiftNodes.submit.classList.remove("loading");
            if (status === 400) {
                _this.showErrors(data.errors);
            }
            else {
                _this.showError(data.errors);
            }
        });
        fetcher.formData = this.serialize();
        fetcher.send();
    };
    // Show errors
    Gift.prototype.showErrors = function (errors) {
        //this.hasErrors = true;
        var _this = this;
        GiftNodes.fields.forEach(function (field) {
            var name = field.name, type = field.type, parent;
            if (errors.hasOwnProperty(name)) {
                var errorsList = document.createElement("div");
                switch (type) {
                    case "textarea":
                        parent = field.parentNode.parentNode;
                        break;
                    case "checkbox":
                        parent = GiftNodes.submit.parentNode;
                        break;
                    default:
                        parent = field.parentNode;
                        break;
                }
                parent.classList.add("has-errors");
                parent.appendChild(errorsList);
                errorsList.classList.add("errors");
                errorsList.innerHTML = _this.getErrorsList(errors[name]);
            }
        });
    };
    // Show error
    Gift.prototype.showError = function (error) {
        console.log(error);
    };
    // Get errors list
    Gift.prototype.getErrorsList = function (data) {
        var errors = "<ul class=\"text-secondary text-bold unstyled-list\">";
        var i = 0;
        for (; i < data.length; i++) {
            errors += "<li>" + data[i] + "</li>";
        }
        errors += "</ul>";
        return errors;
    };
    // remove errors
    Gift.prototype.errorsRemove = function () {
        var errorsContainers = GiftNodes.form.querySelectorAll("div.errors");
        for (var i = 0; i < errorsContainers.length; i++)
            errorsContainers[i].parentElement.removeChild(errorsContainers[i]);
    };
    // Check text
    Gift.prototype.checkText = function (text) {
        return text != null && text.length > 2;
    };
    // Check email
    Gift.prototype.checkEmail = function (email) {
        return this.emailRegex.test(email);
    };
    // Check phone
    Gift.prototype.checkPhone = function (number) {
        return number != null && this.phoneRegex.test(number);
    };
    return Gift;
}());
exports.Gift = Gift;
