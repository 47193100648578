"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.App = exports.AppData = void 0;
// Imports
var core_module_1 = require("../@kilopix/core.module");
var utils_module_1 = require("../@kilopix/utils.module");
var header_component_1 = require("./header/header.component");
var slider_component_1 = require("./slider/slider.component");
var context_component_1 = require("./context/context.component");
var bundle_component_1 = require("./bundle/bundle.component");
var gift_component_1 = require("./gift/gift.component");
var footer_component_1 = require("./footer/footer.component");
var cookie_banner_component_1 = require("./cookie-banner/cookie-banner.component");
var call_to_action_component_1 = require("./call-to-action/call-to-action.component");
var StoresRoot_component_1 = require("./stores/StoresRoot.component");
// App Component (Singleton pattern)
var AppComponent = /** @class */ (function () {
    // Constructor
    function AppComponent() {
        this.sliders = [];
        this.contexts = [];
    }
    // Get instance
    AppComponent.getInstance = function () {
        if (!AppComponent.instance) {
            AppComponent.instance = new AppComponent();
            AppComponent.instance.html = document.getElementsByTagName("html")[0];
            var env = JSON.parse(document.getElementById("env").innerHTML);
            if (env.isPreview) {
                AppComponent.instance.html.classList.add("is-preview");
                exports.AppData.isPreview = true;
            }
            exports.AppData.applicationPath = env.applicationPath;
            exports.AppData.language = env.language;
            exports.AppData.IDnode = env.IDnode;
            exports.AppData.cookiesConsent = env.cookiesConsent;
        }
        return AppComponent.instance;
    };
    // Run
    AppComponent.prototype.run = function () {
        var _this = this;
        exports.AppData.currentPage = document.body.getAttribute("class");
        this.html.classList.replace("no-js", "js");
        this.html.classList.add(exports.AppData.isMobile ? "mobile" : "no-mobile");
        window.addEventListener("load", function () { _this.load(); });
        window.addEventListener("scroll", function () { _this.scroll(); });
        window.addEventListener("resize", function () { _this.resize(500); });
        this.header = new header_component_1.Header();
        this.footer = new footer_component_1.Footer();
        this.bundle = new bundle_component_1.Bundle();
        this.gift = new gift_component_1.Gift();
        // Sliders
        var sliderNodes = document.querySelectorAll(".slider");
        if (sliderNodes.length) {
            sliderNodes.forEach(function (slider) {
                _this.sliders.push(new slider_component_1.Slider(slider));
            });
        }
        // Contexts
        var contextsNodes = document.querySelectorAll(".context");
        if (contextsNodes.length) {
            contextsNodes.forEach(function (context) {
                var htmlContext = context;
                _this.contexts.push(new context_component_1.Context(htmlContext));
            });
        }
        // CallToAction (aka Products)
        if (document.body.classList.contains("products-root")) {
            var callToActionRootElement = document.querySelector(".call-to-action");
            this.callToAction = new call_to_action_component_1.CallToAction(callToActionRootElement);
        }
        // StoresRoot
        if (document.body.classList.contains("stores-root")) {
            this.storesRoot = new StoresRoot_component_1.StoresRoot();
            window.storesRootCallback = function () { _this.storesRoot.mapsStart(); };
        }
        //
        this.resize();
        /**
         *	Rendo globale la funzione che gestisce le operazioni di accettazione
         *	dei cookies. Passaggio obbligato visto che la chiamata alla funzione
         *	avviene inline, nel tag html
         */
        window.masterCookieConsentSet = cookie_banner_component_1.CookieBanner.consentSet;
    };
    // Load
    AppComponent.prototype.load = function () {
        exports.AppData.isLoaded = true;
        //
        this.resize();
    };
    // Resize
    AppComponent.prototype.resize = function (delay) {
        if (delay === void 0) { delay = 0; }
        exports.AppData.mediaQuery = core_module_1.Core.getMediaQuery();
        exports.AppData.size = core_module_1.Core.getSize();
        exports.AppData.orientation = core_module_1.Core.getOrientation();
        AppComponent.instance.html.classList.add("no-transition");
        clearTimeout(AppComponent.resizeTimeout);
        AppComponent.resizeTimeout = setTimeout(function () {
            cookie_banner_component_1.CookieBanner.resizeSpacer();
            AppComponent.instance.html.classList.remove("no-transition");
        }, delay);
        this.header.resize();
        if (this.sliders.length) {
            this.sliders.forEach(function (slider) {
                slider.resize();
            });
        }
        if (this.contexts.length) {
            this.contexts.forEach(function (context) {
                context.resize();
            });
        }
        if (this.callToAction !== undefined)
            this.callToAction.resize();
        this.scroll();
    };
    // Scroll
    AppComponent.prototype.scroll = function () {
        exports.AppData.scrollTop = core_module_1.Core.getScrollTop();
        exports.AppData.scrollBottom = core_module_1.Core.getScrollBottom();
        exports.AppData.scrollPerc = core_module_1.Core.getScrollPerc();
        exports.AppData.scrollDirection = core_module_1.Core.getScrollDirection();
        //
        if (this.callToAction !== undefined)
            this.callToAction.scroll();
    };
    return AppComponent;
}());
// Exports
exports.AppData = {
    mode: process.env.NODE_ENV,
    mediaQuery: 0,
    size: "xs",
    orientation: "portrait",
    scrollTop: 0,
    scrollBottom: 0,
    scrollPerc: 0,
    scrollDirection: "down",
    isMobile: utils_module_1.Utils.isMobile(),
    isLoaded: false,
    applicationPath: "",
    language: "",
    IDnode: 0,
    cookiesConsent: false,
    isPreview: false,
    currentPage: ""
};
exports.App = AppComponent.getInstance();
