"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Header = exports.HeaderNodes = void 0;
// Header nodes
exports.HeaderNodes = {
    root: document.querySelector("body > header"),
    buttonToggleNav: document.querySelector(".button-toggle-nav"),
    buttonToggleSearch: document.querySelector(".button-toggle-search"),
    navWrap: document.querySelector(".nav-wrap > .wrap"),
    nav: document.querySelector(".nav-wrap nav"),
    menu: document.querySelector(".nav-wrap nav ul")
};
// Header
var Header = /** @class */ (function () {
    function Header() {
        exports.HeaderNodes["buttonToggleNav"].addEventListener("click", function (e) {
            exports.HeaderNodes["root"].classList.toggle("has-menu");
            e.preventDefault();
        });
        exports.HeaderNodes["buttonToggleSearch"].addEventListener("click", function (e) {
            exports.HeaderNodes["root"].classList.toggle("has-search");
            e.preventDefault();
        });
    }
    // Resize
    Header.prototype.resize = function () {
        var manuWrapHeight = exports.HeaderNodes["navWrap"].offsetHeight, menuHeight = exports.HeaderNodes["menu"].offsetHeight;
        if (manuWrapHeight <= menuHeight) {
            exports.HeaderNodes["nav"].classList.remove("nav-center");
        }
        else {
            exports.HeaderNodes["nav"].classList.add("nav-center");
        }
        if (exports.HeaderNodes["root"].classList.contains("has-menu")) {
            exports.HeaderNodes["buttonToggleNav"].click();
        }
    };
    return Header;
}());
exports.Header = Header;
