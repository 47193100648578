"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Utils = void 0;
// Utils
var Utils = /** @class */ (function () {
    function Utils() {
    }
    // Is mobile
    Utils.isMobile = function () {
        if (/Mobi/i.test(navigator.userAgent) || /Android/i.test(navigator.userAgent)) {
            return true;
        }
        return false;
    };
    return Utils;
}());
exports.Utils = Utils;
