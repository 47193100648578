"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Footer = void 0;
// Imports
var Fetcher_1 = require("../common/Fetcher");
// Footer nodes
var FooterNodes = {
    root: document.querySelector("body > footer"),
    newsletter: document.querySelector(".form-newsletter"),
    field: document.querySelector(".form-newsletter input[type='email']"),
    checkbox: document.querySelector(".form-newsletter input[type='checkbox']"),
    submit: document.querySelector(".form-newsletter button")
};
// Footer
var Footer = /** @class */ (function () {
    function Footer() {
        this.emailRegex = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        this.email = "";
        this.isLoading = false;
        this.isRegistered = false;
        var envNode = document.getElementById("env");
        if (envNode)
            this.env = JSON.parse(envNode.innerHTML);
        this.drawMessage();
        this.listeners();
    }
    // Draw message
    Footer.prototype.drawMessage = function () {
        var message = document.createElement("p");
        message.classList.add("message");
        message.classList.add("hide");
        message.innerHTML = "Message here...";
        FooterNodes.newsletter.appendChild(message);
        FooterNodes.message = FooterNodes.newsletter.querySelector(".message");
    };
    // Listeners
    Footer.prototype.listeners = function () {
        var _this = this;
        FooterNodes.field.addEventListener("focus", function () {
            if (_this.isRegistered)
                return;
            _this.toggle();
        });
        FooterNodes.field.addEventListener("keyup", function (e) {
            if (_this.isRegistered)
                return;
            _this.email = e.target.value;
            _this.setSubmitStatus();
            _this.toggle();
        });
        FooterNodes.checkbox.addEventListener("change", function (e) {
            _this.setSubmitStatus();
        });
        FooterNodes.submit.addEventListener("click", function (e) {
            e.preventDefault();
            if (_this.validate() && !_this.isLoading) {
                _this.submit();
            }
            else {
                console.log("Submit not allowed");
            }
        });
    };
    // Toggle
    Footer.prototype.toggle = function (open) {
        if (open === void 0) { open = true; }
        if (!open) {
            FooterNodes.newsletter.classList.remove("show-checkbox");
            return;
        }
        if (this.email != "" && FooterNodes.newsletter.classList.contains("show-checkbox"))
            return;
        if (this.email != "") {
            FooterNodes.newsletter.classList.add("show-checkbox");
            FooterNodes.message.classList.add("hide");
        }
        else {
            FooterNodes.newsletter.classList.remove("show-checkbox");
        }
    };
    // Validate
    Footer.prototype.validate = function () {
        if (this.email === "" || !this.checkEmail(this.email) || !FooterNodes.checkbox.checked)
            return false;
        return true;
    };
    // Set submit status
    Footer.prototype.setSubmitStatus = function () {
        if (this.isLoading) {
            FooterNodes.submit.classList.add("loading");
        }
        else {
            FooterNodes.submit.classList.remove("loading");
        }
        if (this.validate() && !this.isLoading) {
            FooterNodes.submit.removeAttribute("disabled");
        }
        else {
            FooterNodes.submit.setAttribute("disabled", true);
        }
    };
    // Send
    Footer.prototype.submit = function () {
        var _this = this;
        this.isLoading = true;
        this.setSubmitStatus();
        var url = this.env.applicationPath + "/Services.aspx?action=newsletter-subscribe&email=" + this.email, fetcher = new Fetcher_1.Fetcher(url, function (data) {
            _this.showMessage(data.message);
        }, function (status, data) {
            _this.showMessage(data.message, "error");
        });
        fetcher.send();
    };
    // Show message
    Footer.prototype.showMessage = function (message, type) {
        if (type === void 0) { type = "success"; }
        this.isLoading = false;
        this.setSubmitStatus();
        if (type === "error") {
            this.toggle(false);
        }
        else {
            this.isRegistered = true;
            this.restore();
        }
        FooterNodes.message.classList.remove("hide");
        FooterNodes.message.innerHTML = message;
    };
    // Restore
    Footer.prototype.restore = function () {
        this.toggle(false);
        FooterNodes.field.value = "";
        FooterNodes.checkbox.checked = false;
        FooterNodes.newsletter.removeChild(FooterNodes.submit);
        this.email = "";
    };
    // Check email
    Footer.prototype.checkEmail = function (email) {
        return this.emailRegex.test(email);
    };
    return Footer;
}());
exports.Footer = Footer;
