"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CookieBanner = void 0;
//	Cookie banner
var CookieBanner = /** @class */ (function () {
    function CookieBanner() {
    }
    //	Content set
    CookieBanner.consentSet = function () {
        var form = document.createElement("form");
        var input = document.createElement("input");
        form.style.height = "0";
        form.style.overflow = "hidden";
        form.action = document.location.href;
        form.method = "post";
        input.name = "cookiesConsent";
        input.value = "y";
        document.body.appendChild(form);
        form.appendChild(input);
        form.submit();
        //console.log( "I've got a cookie!" );
    };
    //	Resize spacer
    CookieBanner.resizeSpacer = function () {
        var cookieBanner = document.getElementById("script-cookie-banner");
        if (cookieBanner) {
            var cookieBannerSpacer = document.getElementById("script-cookie-banner-spacer");
            cookieBannerSpacer.style.height = cookieBanner.getBoundingClientRect().height.toString() + "px";
        }
    };
    return CookieBanner;
}());
exports.CookieBanner = CookieBanner;
